html {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  background-color: rgba(224, 225, 227, 0.7);
  height: 100%;
}

/* Fix first swipe of react-swipeable-views */
.react-swipeable-view-container {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  height: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #919191;
  height: 10px;
  border-radius: 10px;
}

::-webkit-calendar-picker-indicator {
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
